import React from "react";

const Label = ({label}) => {
  let text = label.split(" ");
  if (text.length === 2) {
    return (<> {text[0]} <strong>{text[1]}</strong></>)
  } else if (text.length === 3) {
    return (<>{text[0]} {text[1]} <strong>{text[2]}</strong></>)
  }
  return (<strong className={text[0] === "Prendarios" ? "maxLetter" : ""} >{text[0]}</strong>)
}

export default Label;