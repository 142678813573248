import authAPI from "../../api/authAPI";
import appConfig from "../../config/index";
import { AUTH, LOGOUT, SAVE_PROFILE, SET_INTENDED_URL } from "../types/auth"
import accountAPI from "../../api/accountAPI";

export const login = (user, password, remember = false) => async dispatch => {
  const loginResponse = await authAPI.login({
    data: {
      grant_type: "password",
      client_id: appConfig.clientId,
      client_secret: appConfig.clientSecret,
      scope: "*",
      username: user,
      password: password,
    }
  });

  dispatch({
    type: AUTH,
    payload: {
      tokens: loginResponse.data,
      remember,
      isUserLoading: true,
    },
  });

  try{
    const profileResponse = await accountAPI.profile();
    const user = profileResponse.data.data

    dispatch({
      type: SAVE_PROFILE,
      payload: user,
    });
  }catch(e){
    dispatch({
      type: LOGOUT,
    });
  }

  return loginResponse;
}


export const register = (email, password, redirect = null) => async dispatch => {
  return await authAPI.register({email, password, redirect});
}


export const getProfile = () => async dispatch => {
  const response = await accountAPI.profile();

  dispatch({
    type: SAVE_PROFILE,
    payload: response.data.data,
  });

  return response;
}

export const logout = () => (dispatch) => {
  return authAPI.logout()
    .then(() => {
      dispatch({ type: LOGOUT });
    })
    .catch(err => {
      if(err.response?.status === 401){
        dispatch({ type: LOGOUT });
      }else{
        return Promise.reject(err);
      }
    })
}

export const renewToken = () => async (dispatch, getState) => {
  const tokens = getState().auth.tokens;

  const loginResponse = await authAPI.login({
    data: {
      grant_type: "refresh_token",
      client_id: appConfig.clientId,
      client_secret: appConfig.clientSecret,
      scope: "*",
      refresh_token: tokens.refresh_token + 'XXXX',
    },
  });

  dispatch({
    type: AUTH,
    payload: {
      tokens: loginResponse.data,
      remember: true,
    },
  });

  return loginResponse;
}

export const forgotPassword = email => dispatch => {
  return authAPI.forgotPassword({ data: { email } });
}

export const recoverPassword = data => dispatch => {
  return authAPI.recoverPassword({ data });
}