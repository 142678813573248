import React from 'react'; 
import './NewLayout.scss';

import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {closeSideMenu, openSideMenu} from "../../../store/actions/menu";
import { Footer } from '../FooterLayout/Footer';

import { DitaButton } from '../../../components/Buttons/DitaButton';
import MenuLateral from "../../../components/MenuLateral/MenuLateral";
import {useHistory, useLocation} from "react-router-dom";
import HeaderBar from "../../../components/HeaderBar/HeaderBar";

const NewLayout = (
  {
    children,
    user,
    isUserLoading,
    token,
    sideMenuIsOpen,
    closeSideMenu,
    openSideMenu,
    linkDita,
    disabledDita
  }
) => {

  const history = useHistory();
  const location = useLocation();
  const showFooter = () => {
    if (window.innerWidth > 600) {
      return <Footer />
    }
  }

  return (
    <div className="NewLayout">
      <div className="main-section">
        <MenuLateral
          menu={user?.menu}
          loading={isUserLoading}
          token={token}
          isOpen={sideMenuIsOpen}
          onOpenMenu={openSideMenu}
          onCloseMenu={closeSideMenu}
          onInternalRedirect={history.push}
        />
        <HeaderBar
          showNavOptions={location.pathname !== "/"}
          handleOpenMenu={openSideMenu}
          menu={user?.menu}
          token={token}
          onInternalRedirect={history.push}
        />
        <div className="content-wrapper">
            {children}
          <DitaButton link={linkDita} disabled={disabledDita}/>
        </div>
        {showFooter()}
      </div>
    </div>
  );
}

NewLayout.propTypes = {
  title: PropTypes.string,
  backAction: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  topBarContent: PropTypes.node,
  user: PropTypes.shape({
    menu: PropTypes.object,
    impersonating: PropTypes.shape({
      logout_url: PropTypes.string,
      imposter_name: PropTypes.string,
      imposter_identifier: PropTypes.string,
    })
  })
};

const mapStateToProps = state => ({
  sideMenuIsOpen: state.menu.sideMenuIsOpen,
  user: state.auth.user,
  token: state.auth.tokens?.access_token,
  isUserLoading: state.auth.isUserLoading,
});

const mapDispatchToProps = {
  closeSideMenu,
  openSideMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewLayout);