export const SET_FISCAL_CONDITIONS = "SET_FISCAL_CONDITIONS";
export const SET_BUSINESS_TYPES = "SET_BUSINESS_TYPES";
export const SET_PROVINCES = "SET_PROVINCES";
export const SET_BUSINESS_ACTIVITIES = "SET_BUSINESS_ACTIVITIES";
export const SET_SOCIAL_MEDIA_SERVICES = "SET_SOCIAL_MEDIA_SERVICES";
export const SET_GLOBAL_LOADER = "SET_GLOBAL_LOADER";
export const SET_PLATFORMS = "SET_PLATFORMS";
export const SET_PLATFORM_ROLES = "SET_PLATFORM_ROLES";
export const CLEAR_ALERTS = "CLEAR_ALERTS";
export const ADD_ALERT = "ADD_ALERT";
export const DISMISS_ALERT = "DISMISS_ALERT";
export const ADVERTISING_BANNER = "ADVERTISING_BANNER";