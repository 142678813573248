import React, {useCallback, useState} from "react";
import Backdrop from "../Backdrop/Backdrop";
import PropTypes from 'prop-types';
import Spinner from "../Spinner/Spinner";
import Logo from '../../assets/images/worldcup/logo.svg';
import {Badge, Collapse} from "react-bootstrap";
import {
  FaCalculator,
  FaFacebook, 
  GoChevronDown, GoChevronUp,
  FaInstagram, FaRecycle,
  FaWhatsapp,
} from "react-icons/all";

import { ReactComponent as Document } from '../../assets/icons/documentl.svg'
import { ReactComponent as Shield } from '../../assets/icons/shieldl.svg'
import { ReactComponent as VehicleGroup } from '../../assets/icons/vehicle-group.svg'
import { ReactComponent as Cart } from '../../assets/icons/cart.svg'
import { ReactComponent as User } from '../../assets/icons/user.svg'
import { ReactComponent as UserGroup } from '../../assets/icons/user-group.svg'
import { ReactComponent as Logout } from '../../assets/icons/logout.svg'
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg'
import { ReactComponent as Home } from '../../assets/icons/home.svg'
import { ReactComponent as Bell } from '../../assets/icons/bell.svg'
import { ReactComponent as Avatar } from '../../assets/icons/avatar.svg'
import MenuButton from "../Buttons/MenuButton/MenuButton";
import {NewAbstractIcon} from "../HeaderBar/AbstractIcon";
import Label from "../Label/LabelMenu";


/**
 *
 * @param isOpen
 * @param onOpenMenu
 * @param onCloseMenu
 * @param logout
 * @param menu
 * @param loading
 * @param token
 * @param platformKey
 * @param onInternalRedirect
 * @returns {JSX.Element}
 * @constructor
 */
const OnboardingMenu = ({
                          isOpen,
                          onOpenMenu,
                          onCloseMenu,
                          menu,
                          loading,
                          token,
                          platformKey = null,
                          onInternalRedirect,
                        }) => {
  return (
    <>
      <aside className={`OnboardingMenu ${isOpen ? 'OnboardingMenu--open' : ''}`}>
        <div className="OnboardingMenu__top">
          <div className="OnboardingMenu__logo-container">
            <img className="OnboardingMenu__logo" src={Logo} alt="Logo"/>
          </div>
          <div className="OnboardingMenu__user-container">
            {loading ? (<Spinner size={20}/>) : (
              <>
                <span>
                  ¡Hola {menu.user_data.fullname}!
                </span>
              </>
            )}
          </div>
        </div>
        <nav className="OnboardingMenu__navbar-container">
          {loading ? (
            <Spinner size={20}/>
          ) : (
            <Navbar
              menu={menu}
              token={token}
              platformKey={platformKey}
              onInternalRedirect={onInternalRedirect}
              onCloseMenu={onCloseMenu}
            />
          )}
        </nav>
      </aside>
      {isOpen && (
        <Backdrop
          onClick={isOpen ? onCloseMenu : onOpenMenu}
          onBack={isOpen ? onCloseMenu : onOpenMenu}
          index={150}
          className="OnboardingMenu__backdrop"
        />
      )}
    </>
  )
}

OnboardingMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  menu: PropTypes.object.isRequired,
  onOpenMenu: PropTypes.func.isRequired,
  onCloseMenu: PropTypes.func.isRequired,
  onInternalRedirect: PropTypes.func.isRequired,
  token: PropTypes.string,
  platformKey: PropTypes.string,
};


/**
 *
 * @param ico
 * @returns {JSX.Element|null}
 * @constructor
 */
const AbstractIcon = ({ico}) => {
  switch(ico){
    case 'home': return <Home/>;
    case 'bell': return <Bell/>;
    case 'recycle': return <FaRecycle/>;
    case 'file': return <Document/>;
    case 'cart': return <Cart/>;
    case 'shield': return <Shield/>;
    case 'car': return <VehicleGroup/>;
    case 'user': return <User/>;
    case 'users': return <UserGroup/>;
    case 'calculator': return <FaCalculator/>;
    case 'whatsapp': return <FaWhatsapp/>;
    case 'instagram': return <FaInstagram/>;
    case 'facebook': return <FaFacebook/>;
    case 'arrow': return <Arrow/>;
    case 'logout': return <Logout/>;
    case 'avatar': return <Avatar/>;
    default: return null;
  }
}

/**
 *
 * @param menu
 * @param closeSideMenu
 * @param token
 * @param logout
 * @param onInternalRedirect
 * @param platformKey
 * @param onCloseMenu
 * @returns {JSX.Element}
 * @constructor
 */
const Navbar = ({menu, closeSideMenu, token, onInternalRedirect, platformKey, onCloseMenu}) => {
  const [groupOpen, setGroupOpen] = useState(null);

  const groupToggleHandler = useCallback((groupKey) => {
    setGroupOpen(groupKey === groupOpen ? null : groupKey);
  }, [groupOpen]);

  const buildNav = (items) => {
    return items.map(item => {
      if (item.active && item.name !== "Home") {
        return (
          <NavbarLine
            key={item.id}
            closeSideMenu={closeSideMenu}
            line={item}
            groupToggleHandler={groupToggleHandler}
            groupOpen={groupOpen}
            icon={<NewAbstractIcon icon={item.icon}/>}
            color={item.color}
            token={token}
            onInternalRedirect={onInternalRedirect}
            platformKey={platformKey}
            onCloseMenu={onCloseMenu}
          />
        )
      }
    });
  }
  return (
    <ul className="Navbar">
      {buildNav(menu.items)}
      <li className="Navbar__filler"/>
      <hr className="LineMenu pb-15" />
      <div className="Navbar__buttons-container">
        <MenuButton 
          handleOnClick={e => console.log("click")}
          textLigth={"Mi"}
          textBold ={"Cuenta"}
          // imgSrc={}
        />
        <MenuButton 
          handleOnClick={e => console.log("click")}
          textBold ={"Usuarios"}
          // imgSrc={}
        />
      </div>
      
      <NavbarItem className="NavbarItem__logout" to={menu.logout_url} icon={<Logout/>}>
        Cerrar Sesión
      </NavbarItem>
    </ul>
  )
}

/**
 *
 * @param line
 * @param groupOpen
 * @param groupToggleHandler
 * @param onCloseMenu
 * @param icon
 * @param token
 * @param onInternalRedirect
 * @param platformKey
 * @returns {JSX.Element|null}
 * @constructor
 */
const NavbarLine = ({
                      line,
                      groupOpen,
                      groupToggleHandler,
                      onCloseMenu,
                      icon,
                      color,
                      token,
                      onInternalRedirect,
                      platformKey
                    }) => {

  //TODO: unificar en un hook
  let to = '/';

  if(line.platform !== platformKey){
    to = line.url

    if(line.platform){
      to += `?access_token=${token}`; //TODO: Fix, hay que armar el querystring correcto
    }
  }else{
    to = line.path || line.url;
  }

  const onClickHandler = useCallback((e) => {

    onCloseMenu && onCloseMenu();
    
    if(to.startsWith('/') && onInternalRedirect){
      e.preventDefault();
      onInternalRedirect(to);
    }

  }, [to]);

  switch(line.type){
    case 'item':
      return (
        <NavbarItem
          target={line.external_window ? '_blank' : ''}
          icon={icon}
          onClick={onClickHandler}
          to={to}
          badge={line.badge}
          color={color}
          //className={line.highlight ? 'NavbarItem--highlight' : ''}
          clasName={'divider'}
        >
          {<Label label={line.name} />}
        </NavbarItem>
      )

    case 'group': return (
      <NavbarGroup
        groupKey={line.name}
        icon={icon}
        groupOpen={groupOpen}
        onToggle={groupToggleHandler}
        label={line.name}
        badge={line.badge}
        color={color}
      >
        {line.items.map(subLine => (
          <NavbarLine
            key={subLine.id}
            token={token}
            line={subLine}
            groupOpen={groupOpen}
            onToggle={groupToggleHandler}
            platformKey={platformKey}
            onInternalRedirect={onInternalRedirect}
            onCloseMenu={onCloseMenu}
            color={color}
          />
        ))}
      </NavbarGroup>
    )

    case 'divider': return (<NavbarDivider/>)

    default:
      return null;
  }
}

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const NavbarDivider = ({

                       }) => {
  return (
    <li className="NavbarDivider"/>
  );
}

/**
 *
 * @param icon
 * @param to
 * @param children
 * @param onClick
 * @param target
 * @param className
 * @param badge
 * @returns {JSX.Element}
 * @constructor
 */
const NavbarItem = ({
                      icon,
                      to,
                      children,
                      onClick,
                      target,
                      className,
                      badge,
                      color
                    }) => {
  return (
    <>
      <li  className={`NavbarItem ${className || ''}`}>
        <a style={{color:color}} href={to || '#'} onClick={onClick} target={target}>
          <span style={{color:color}} className="NavbarItem__icon">
            {icon}
          </span>
          {children}
          {badge && (<NavbarItemBadge text={badge}/>)}
        </a>
      </li>
      {icon ? <hr className="LineMenu" /> : ""}
    </>
  );
}

/**
 *
 * @param text
 * @returns {JSX.Element}
 * @constructor
 */
const NavbarItemBadge = ({
                           text
                         }) => {
  return (
    <span className="NavbarItem__badge-container">
      <Badge variant="primary">
        {text}
      </Badge>
      {<hr className="LineMenu" />}
    </span>
  );
}

/**
 *
 * @param children
 * @param icon
 * @param groupKey
 * @param label
 * @param groupOpen
 * @param onToggle
 * @param badge
 * @returns {JSX.Element}
 * @constructor
 */
const NavbarGroup = ({
                       children,
                       icon,
                       groupKey,
                       label,
                       groupOpen,
                       onToggle,
                       badge,
                       color
                     }) => {
  const onClick = useCallback(() => {
    onToggle(groupKey);
  }, [onToggle, groupKey]);

  const open = groupKey && groupKey === groupOpen;

  return (
    <>
      <li className="NavbarItem NavbarGroup">
        <a style={{color:color}} href='#' onClick={onClick}>
          <span style={{color:color}} className="NavbarItem__icon">
            {icon}
          </span>
          <Label label={label} />
          {badge && (<NavbarItemBadge text={badge}/>)}
          <div className="NavbarGroup__collapse-ico">
            {open ? (<GoChevronUp/>) : (<GoChevronDown/>)}
          </div>
        </a>
        <Collapse in={open}>
          <div>
            <ul className="NavbarGroup__list">
              {children}
            </ul>
          </div>
        </Collapse>
      </li>
      <hr className="LineMenu" />
    </>
  );
}

export default OnboardingMenu;

export {
  Navbar,
  NavbarDivider,
  NavbarGroup,
  NavbarItem,
  NavbarItemBadge,
  NavbarLine,
  OnboardingMenu,
  AbstractIcon,
};