import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as RoundedCrevronIcon} from '../../assets/icons/chevron-rounded.svg'
import {ReactComponent as TriangleIcon} from '../../assets/icons/triangle.svg'

import './ButtonHeader.scss'
import {AbstractIcon} from '../OnboardingMenu/OnboardingMenu';

const ButtonHeaderItem = ({
    children,
    icon,
    to,
    onClick,
    target,
    variant = 'primary',
}) => {

    return (
        <a className={"ButtonHeader__wrap"} href={to || '#'} onClick={onClick} target={target}>
            <div className={`ButtonHeader ButtonHeader__${variant}`}>
                {icon}
                {children && <span>{children}</span>}
            </div>
        </a>
    )
}

const ButtonHeaderGroup = ({
    children,
    label,
    icon,
    variant = 'primary',
    showMenu = true
}) => {

    const [open, setOpen] = useState(false)
    const buttonRef = useRef(null)

    useEffect(() => {
        const shouldClose = e => {
            if (buttonRef && !buttonRef.current.contains(e.target) || buttonRef.current.contains(e.target) && open) {
                setOpen(false)
            }
        }
        window.document.addEventListener('click', shouldClose)
        return () => window.document.removeEventListener('click', shouldClose)
    }, [])

    const handleClick = e => {
        setOpen(!open)
    }

    return (
        <div ref={buttonRef} onClick={handleClick} className={`ButtonHeader__wrap ButtonHeader__wrap_drop${!showMenu ? '--top' : ''}`}>
            <div className={`ButtonHeader ButtonHeader__${variant} ButtonHeader__drop`}>
                {icon}
                {label && <span>{label}</span>}
                <span className={open ? "ButtonHeader__chevron_down" : "ButtonHeader__chevron"}><RoundedCrevronIcon /></span>
            </div>
            <div className={open ? 'ButtonHeader__dropDown ButtonHeader__dropDown_open' : 'ButtonHeader__dropDown'}>
                <ul>
                    {children}
                </ul>
            </div>
        </div>
    )
}

export const ButtonHeaderLine = ({
    key,
    line,
    icon,
    color,
    token,
    onInternalRedirect,
    platformKey,
    dropDownLine = false,
    target,
    showMenu
}) => {

    //TODO: unificar en un hook
    let to = '/';

    if (line.platform && line.platform !== platformKey) {
        to = line.url

        if (line.platform) {
            to += `?access_token=${token}`; //TODO: Fix, hay que armar el querystring correcto
        }
    } else {
        to = line.path || line.url;
    }


    const onClickHandler = useCallback((e) => {
        if (to && to.startsWith('/') && onInternalRedirect) {
            e.preventDefault();
            e.stopPropagation();
            onInternalRedirect(to);
        }
    }, [to]);

    if (dropDownLine) {
        return (
            <li key={key}>
                <a href={to || '#'} onClick={onClickHandler} className="button-header__line" style={{color:color}} target={target} >
                    <div><TriangleIcon /></div>
                    <div>{icon}</div>
                    <div>{line.name}</div>
                </a>
            </li>
        )
    }

    switch (line.type) {
        case 'button':
            return (
                <ButtonHeaderItem
                    target={line.external_window ? '_blank' : ''}
                    icon={<AbstractIcon ico={line.icon} />}
                    onClick={onClickHandler}
                    to={to}
                    badge={line.badge}
                    color={color}
                >
                    
                </ButtonHeaderItem>
            )
        case 'button_group': return (
            <ButtonHeaderGroup
                groupKey={line.name}
                icon={<AbstractIcon ico={line.icon} />}
                label={line.name}
                badge={line.badge}
                color={color}
                showMenu={showMenu}
            >
                {line.items.map(subLine => (
                    <ButtonHeaderLine
                        line={subLine}
                        key={subLine.id}
                        token={token}
                        platformKey={platformKey}
                        onInternalRedirect={onInternalRedirect}
                        color={subLine.color}
                        icon={<AbstractIcon ico={subLine.icon} />}
                        dropDownLine={true}
                        target={line.external_window ? '_blank' : ''}
                    />
                ))}
            </ButtonHeaderGroup>
        )
        default:
            return null;
    }
}

const DropDown = ({
    options,
    open,
}) => {
    return (
        <div className={open ? 'ButtonHeader__dropDown ButtonHeader__dropDown_open' : 'ButtonHeader__dropDown'}>
            <ul>
                {options.map((item, key) => (<li key={key}>{item.Icon}<span>{item.text}</span></li>))}
            </ul>
        </div>
    )
}

export const ButtonHeader = ({
    variant = 'primary',
    isDropdown = false,
    dropdownOptions = [],
    Icon,
    text,
    onClick,
}) => {
    const [open, setOpen] = useState(false)
    const buttonRef = useRef(null)

    useEffect(() => {
        if (isDropdown) {
            const shouldClose = e => {
                console.log("shouldClose")
                if (buttonRef && !buttonRef.current.contains(e.target) || buttonRef.current.contains(e.target) && open) {
                   
                    setOpen(false)
                }
            }

            window.document.addEventListener('click', shouldClose)
            return () => window.document.removeEventListener('click', shouldClose)
        }
    }, [])

    const handleClick = e => {
        console.log("handleClick 199")
        e.preventDefault()
        e.stopPropagation()
        if (isDropdown) {
            setOpen(!open)
        } else if (onClick !== undefined) {
            console.log(onClick)
            onClick(e)
        }
    }

    return (
        <div ref={buttonRef} onClick={handleClick} className={isDropdown ?'ButtonHeader__wrap ButtonHeader__wrap_drop' : "ButtonHeader__wrap"}>
            <div className={isDropdown
                ? `ButtonHeader ButtonHeader__${variant} ButtonHeader__drop`
                : `ButtonHeader ButtonHeader__${variant}`}
            >
                <Icon />
                {text && <span>{text}</span>}
                {isDropdown && <span className={open ? "ButtonHeader__chevron_down" : "ButtonHeader__chevron"}><RoundedCrevronIcon /></span>}

            </div>
            {isDropdown && <DropDown open={open} options={dropdownOptions} />}
        </div>
    )
}

ButtonHeader.propTypes = {
    variant: PropTypes.oneOf(['primary', 'secondary']),
    isDropdown: PropTypes.bool,
    dropdownOptions: PropTypes.arrayOf({text: PropTypes.string, Icon: PropTypes.node}),
    Icon: PropTypes.any,
    text: PropTypes.string,
    onClick: PropTypes.func,
}