import React from 'react';
import {ButtonHeaderLine} from '../ButtonHeader/ButtonHeader';


export const HeaderButtons = ({
    menu,
    platformKey,
    token,
    onInternalRedirect,
    showMenu
}) => {

    return (
        <div className='header-buttons-wrap'>
            {menu.items.filter(e => (e.type === 'button' || e.type === 'button_group')).map(line => (
                <div className='header-buttons-wrap' style={{justifyContent: 'end'}}>
                    <ButtonHeaderLine
                        key={line.id}
                        token={token}
                        line={line}
                        platformKey={platformKey}
                        onInternalRedirect={onInternalRedirect}
                        color={line.color}
                        showMenu={showMenu}
                    />
                </div>
            ))}
        </div>
    );

}