import React from 'react'

import DitaIcon from '../../../assets/images/home/DitaIcon.png'
import './index.scss'

export const DitaButton = ({link, disabled = true}) => {

  if (disabled) {
    return null
  }

  return (
    <div className='button-dita'>
      <a
        href={link}
        className="link-simulator floating-whatsapp"
        target="_blank"
      >
        <div className='button-dita__container'>
          <div className='button-dita__hide'></div>
          <div className='button-dita__content'>
            <span className='button-dita__label'>
              ¿Necesitas ayuda?
            </span>
          </div>
        </div>

        <img src={DitaIcon} alt='dita' className='button-dita__img' />
      </a>
    </div>
  )
}

export default React.memo(DitaButton)