import React from 'react';
import './MenuButton.css';
import {ReactComponent as Users} from './users.svg';
import {ReactComponent as User} from './user.svg';

const MenuButton = ({
    handleOnClick,
    textLigth = null,
    textBold = null,
    imgSrc = null,
}) => {
    return (
        <>
            <div className='menu-user-button'>
                <a
                    className='user-acount-link'
                    onClick={handleOnClick}
                >
                    {
                        (imgSrc === 'users') ?
                            <Users className='user-acount-link' />
                            :
                            <User className='user-acount-link' />
                    }
                    {textLigth} <b>{textBold}</b>
                </a>
            </div>
        </>

    )
}

export default MenuButton;