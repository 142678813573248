import React from 'react';
import {Carousel} from "react-responsive-carousel";
import {Banner} from "./Banner";
import Spinner from "../../components/Spinner/Spinner";
import PropTypes from 'prop-types';

import './AdvertisingBanner.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const AdvertisingBanner = ({advertisingBanner}) => {

  const configCarousel = {
    autoPlay: true,
    showStatus: false,
    infiniteLoop: true,
    showThumbs: false
  }
  return advertisingBanner === null
  ? (
    <div className='advertising-spinner'>
      <Spinner size={window.innerWidth < 600 ? 30 : 60}/>
    </div>
  )
  : (
    <Carousel {...configCarousel}>
      {
        advertisingBanner.map((item) =>
          <Banner key={item.id} {...item} />)
      }
    </Carousel>
  );
}

AdvertisingBanner.propTypes = {
  advertisingBanner: PropTypes.array.isRequired
}

export default React.memo(AdvertisingBanner);