import React, {useEffect} from 'react';
import NewLayout from './../layouts/NewLayout/NewLayout';
import './HomePage.scss';
import {Row, Container} from "react-bootstrap";
import BoxButtonsHome from '../../components/BoxButtonsHome/BoxButtonsHome';
import AdvertisingBanner from '../../components/AdvertisingBanner/AdvertisingBanner';
import { useSelector } from 'react-redux';

const HomePage = () => {

  const {advertisingBanner} = useSelector((state) => state.common.extra);
  const {items, help_menu} = useSelector((state) => state.auth.user.menu);
  const {url, disabled} = help_menu.find(item => item.id === "help-whatsapp")
//console.log("🚀 ~ file: HomePage.js ~ line 12 ~ HomePage ~ menuOptions", items, help_menu)
  return (
    <NewLayout title="Inicio" linkDita={url} disabledDita={disabled}>
      <div className="HomePage">
      <AdvertisingBanner advertisingBanner={advertisingBanner} />
        <Container>
          <Row className='justify-content-md-center'>
            <div className='home-center'>
              <BoxButtonsHome menu={items} />
            </div>
          </Row>
        </Container>
      </div>
    </NewLayout>
  );
}

HomePage.propTypes = {

};

export default HomePage;