import HttpService from "./HttpService"

const httpService = HttpService.getInstance()

export default {
  getFiscalConditions: async () => await httpService.authCall("GET", '/api/fiscal_conditions'),
  getBusinessActivities: async () => await httpService.authCall("GET", '/api/business_activities'),
  getBusinessTypes: async () => await httpService.authCall("GET", '/api/business_types'),
  getSocialMediaServices: async () => await httpService.authCall("GET", '/api/social_media_services'),
  getPlatforms: async () => await httpService.authCall("GET", '/api/platforms'),
  getProvinces: async () => await httpService.authCall("GET", '/api/provinces'),
  getPlatformRoles: async () => await httpService.authCall("GET", '/api/platform_roles'),
  checkEmailUnavailable: async (email, idIgnore) => await httpService.call("GET", '/api/unavailable_emails', {params: {email, id: idIgnore || ''}}),
  checkBusinessNameUnavailable: async (name, idIgnore) => await httpService.call("GET", '/api/unavailable_business_names', {params: {name, id: idIgnore || ''}}),
  getAdvertisingBanner: async () => await httpService.authCall("GET", '/api/advertising-banner'),
}
