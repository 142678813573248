import React, {useCallback, useState} from 'react';
import Logo2 from '../../assets/images/logo2.png'
import {ReactComponent as DownIcon} from '../../assets/icons/down.svg'
import {ReactComponent as MenuIcon} from '../../assets/icons/menu.svg'
import {ReactComponent as BellIcon} from '../../assets/icons/bell.svg'
import {ButtonHeader} from '../ButtonHeader/ButtonHeader';
import {HeaderButtons} from './HeaderButtons';
import {AbstractIcon} from '../OnboardingMenu/OnboardingMenu';
import {NewAbstractIcon} from "./AbstractIcon";
import Label from "../Label/LabelMenu";

require('./HeaderBar.scss')

const OptionGroup = ({
    children,
    groupKey,
    onToggle,
    groupOpen,
    color,
    label,
    icon,
}) => {

    const onClick = useCallback(() => {
        onToggle(groupKey);
    }, [onToggle, groupKey]);

    const open = groupKey && groupKey === groupOpen;

    return (
        <div onClick={onClick}>
            <div
                className={open
                  ? `nav-option noselect nav-option-collapsable nav-option-open__${label.replace(" ", "-")}`
                  : `nav-option nav-option__${label.replace(" ", "-")} noselect nav-option-collapsable`
                }
                style={{color: color}}
            >
                <span className="nav-option-icon"><NewAbstractIcon icon={icon} /></span>
                <span className='nav-option-text'>
                    <Label label={label} />
                </span>
                <span className="nav-option-icon"><NewAbstractIcon icon="chevron-menu" /></span>
            </div>
            <div className={open ? "nav-option-dropdown nav-option-dropdown-open" : "nav-option-dropdown"}>
                <ul>
                    {children}
                </ul>
            </div>
        </div>
    )
}


const OptionItem = ({
    id,
    icon,
    to,
    onClick,
    target,
    color,
    label,
    isSubItem
}) => {
    return (
        <a className={`nav-option${!isSubItem ? ` nav-option__${id}` : ''}`} style={{color: color}} href={to || '#'} onClick={onClick} target={target}>
            <span className="nav-option-icon"><NewAbstractIcon icon={icon} /></span>
            <span className='nav-option-text'>
                <Label label={label} />
            </span>
        </a>
    )
}

const OptionLine = ({
    token,
    line,
    groupOpen,
    groupToggleHandler,
    platformKey,
    onInternalRedirect,
    onCloseMenu,
    color,
    isSubItem = false,
    isActive
}) => {

    let to = '/';

    if (line.platform !== platformKey) {
        to = line.url

        if (line.platform) {
            to += `?access_token=${token}`; //TODO: Fix, hay que armar el querystring correcto
        }
    } else {
        to = line.path || line.url;
    }

    const onClickHandler = useCallback((e) => {

        onCloseMenu && onCloseMenu();

        if (to.startsWith('/') && onInternalRedirect) {
            e.preventDefault();
            onInternalRedirect(to);
        }

    }, [to]);
    switch (line.type) {
        case 'item':
            return (
                <OptionItem
                    key={line.id}
                    id={line.name.replace(" ", "-")}
                    icon={line.icon}
                    label={line.name}
                    color={color}
                    to={to}
                    onClick={onClickHandler}
                    isSubItem={isSubItem}
                />
            )
        case 'group':
            return (
                <OptionGroup
                    groupKey={line.id}
                    label={line.name}
                    icon={line.icon}
                    color={color}
                    groupOpen={groupOpen}
                    onToggle={groupToggleHandler}
                >
                    {line.items.map(subLine => (
                        <li>
                            <OptionLine
                                key={subLine.id}
                                token={token}
                                line={subLine}
                                groupOpen={groupOpen}
                                groupToggleHandler={groupToggleHandler}
                                platformKey={platformKey}
                                onInternalRedirect={onInternalRedirect}
                                onCloseMenu={onCloseMenu}
                                color={color}
                                isSubItem={true}
                                isActive={subLine.active}
                            />
                        </li>
                    )
                    )}
                </OptionGroup>
            )
        default:
            return null;
    }

}




const NavOption = ({
    text = "",
    subOptions = [],
    icon,
    groupToggleHandler,
    groupOpen,
    groupKey,
    color,

    token,
    onInternalRedirect,
    platformKey,
}) => {

    const open = groupKey && groupKey === groupOpen;

    if (subOptions.length > 0) {
        return (
            <div onClick={() => groupToggleHandler(groupKey)}>
                <div
                    className={open
                        ? "nav-option nav-option-collapsable nav-option-open"
                        : "nav-option nav-option-collapsable"
                    }

                    style={{color: color}}
                >
                    <span className="nav-option-icon">{icon}</span>
                    <span>{text}</span>
                    <span className="nav-option-icon"><DownIcon /></span>
                </div>
                <div className={open ? "nav-option-dropdown nav-option-dropdown-open" : "nav-option-dropdown"}>
                    <ul>
                        {subOptions.map((item, key) => (<li><Label label={item.name} /></li>))}
                    </ul>
                </div>
            </div>
        )
    }

    return (
        <div className="nav-option" style={{color: color}}>
            <span className="nav-option-icon">{icon}</span>
            <span>{text}</span>
        </div>
    )
}


const HeaderOptions = ({
    menu,
    platformKey,
    token,
    onInternalRedirect,
    onCloseMenu,
}) => {

    const [groupOpen, setGroupOpen] = useState(null);

    const groupToggleHandler = useCallback((groupKey) => {
        setGroupOpen(groupKey === groupOpen ? null : groupKey);
    }, [groupOpen]);
    return (
        <>
            {menu.items.filter(e => e.header && e.active).map(line => (
                <OptionLine
                    key={line.id}
                    token={token}
                    line={line}
                    groupOpen={groupOpen}
                    groupToggleHandler={groupToggleHandler}
                    platformKey={platformKey}
                    onInternalRedirect={onInternalRedirect}
                    onCloseMenu={onCloseMenu}
                    color={line.color}
                />
            ))}
        </>
    )
}

const HeaderBar = ({
    showNavOptions = true,
    handleOpenMenu,
    menu,
    token,
    onInternalRedirect,
    platformKey,
}) => {

    const items = menu.items.filter(e => (e.header && (e.type === 'button' || e.type === 'button_group')))


    return (
        <div className="header-nav-root">
            <div className="header-nav-content">
                <div className="header-nav-content-item__main show-in-desktop">
                    <div className='header-nav-logo header-nav-item' style={{
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        marginLeft: '96px'
                    }}>
                        <div style={{height: 'auto'}}><img width={190} src={Logo2} /></div>
                    </div>
                    <div className='header-nav-item header-nav-notification-right'>
                        <HeaderButtons showMenu={showNavOptions} menu={menu} token={token} platformKey={platformKey} onInternalRedirect={onInternalRedirect} />
                    </div>
                </div>
                <div className="header-nav-content-item__main show-in-movil">
                    <div className='header-nav-item'>
                        <ButtonHeader showMenu={showNavOptions} variant='secondary' Icon={BellIcon} />
                    </div>
                    <div className='header-nav-item'>
                        <div style={{height: 'auto'}}><img width={160} src={Logo2} /></div>
                    </div>
                    <div className='header-nav-hamburger header-nav-item'>
                        <ButtonHeader onClick={handleOpenMenu} variant='secondary' Icon={MenuIcon} />
                    </div>
                </div>
                {showNavOptions &&
                    <div className="header-nav-content-item__options show-in-desktop">
                        <HeaderOptions menu={menu} token={token} platformKey={platformKey} onInternalRedirect={onInternalRedirect} />
                    </div>
                }
            </div>

        </div>
    )
}
export default HeaderBar;