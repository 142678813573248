import {
  SET_GLOBAL_LOADER,
  SET_FISCAL_CONDITIONS,
  SET_BUSINESS_TYPES,
  SET_PROVINCES,
  SET_BUSINESS_ACTIVITIES,
  SET_SOCIAL_MEDIA_SERVICES, SET_PLATFORMS, SET_PLATFORM_ROLES,
  CLEAR_ALERTS,
  ADD_ALERT,
  DISMISS_ALERT,
  ADVERTISING_BANNER,
} from "../types/common";

import commonAPI from "../../api/commonAPI";

export const showGlobalLoader = (active = true) => ({
  type: SET_GLOBAL_LOADER,
  payload: active,
})

export const getSocialMediaServices = () => async dispatch => {
  const response = await commonAPI.getSocialMediaServices();

  dispatch({
    type: SET_SOCIAL_MEDIA_SERVICES,
    payload: response.data.data,
  })

  return response;
}

export const getBusinessTypes = () => async dispatch => {
  const response = await commonAPI.getBusinessTypes();

  dispatch({
    type: SET_BUSINESS_TYPES,
    payload: response.data.data,
  })

  return response;
}

export const getBusinessActivities = () => async dispatch => {
  const response = await commonAPI.getBusinessActivities();

  dispatch({
    type: SET_BUSINESS_ACTIVITIES,
    payload: response.data.data,
  })

  return response;
}

export const getFiscalConditions = () => async dispatch => {
  const response = await commonAPI.getFiscalConditions();

  dispatch({
    type: SET_FISCAL_CONDITIONS,
    payload: response.data.data,
  })

  return response;
}

export const getPlatforms = () => async dispatch => {
  const response = await commonAPI.getPlatforms();

  dispatch({
    type: SET_PLATFORMS,
    payload: response.data.data,
  })

  return response;
}

export const getPlatformRoles = () => async dispatch => {
  const response = await commonAPI.getPlatformRoles();

  dispatch({
    type: SET_PLATFORM_ROLES,
    payload: response.data.data,
  })

  return response;
}

export const getProvinces = () => async dispatch => {
  const response = await commonAPI.getProvinces();

  dispatch({
    type: SET_PROVINCES,
    payload: response.data.data,
  })

  return response;
}

export const clearAllAlerts = () => async dispatch => {
  dispatch({
    type: CLEAR_ALERTS,
    payload: null,
  })
}

export const addAlert = ({title, message, type = 'danger'}) => async dispatch => {
  dispatch({
    type: ADD_ALERT,
    payload: {title, message, type},
  })
}

export const dismissAlert = (id) => async dispatch => {
  dispatch({
    type: DISMISS_ALERT,
    payload: {id},
  })
}

export const getAdvertisingBanner= () => async dispatch => {
  const response = await commonAPI.getAdvertisingBanner();

  dispatch({
    type: ADVERTISING_BANNER,
    payload: response.data.data,
  })

  return response;
}