import React from 'react';
import Card from 'react-bootstrap/Card';
import {Col, Row, p, Container} from "react-bootstrap";
import './styles.css';
import Logo from '../../../assets/images/home/logoFooter.svg'
import IconInstagram from '../../../assets/images/socialMedia/Instagram.png'
import IconFacebook  from '../../../assets/images/socialMedia/Facebook.png'
import IconLinkedin  from '../../../assets/images/socialMedia/Linkedin.png'
import IconYouTube   from '../../../assets/images/socialMedia/YouTube.png'

import useIsMobile from "../../../hooks/useIsMobile";

const onClickUp = () => window.scrollTo({
  top: 0,
  left: 0,
  behavior: 'smooth'
}) ;

export const Footer = () => {

  const isMobile = useIsMobile({maxWidth : 1200});

  return (
    <>
      { !isMobile &&
        <Card.Footer className="footer">
          <div className='div-footer'>
            
            <Row>
              
              <Col sm={4}>
                <div className='title-footer'>
                  <div className='min-weight'>Somos</div> Decreditos
                </div>
                <div className='text-footer'>
                  <p>
                    Hace más de 20 años que somos una empresa que crece y se renueva, con sucursales en las ciudades más importantes del país y con más de 4500 agencias. 
                  </p>
                  <p>
                    Nos enorgullece diferenciarnos por nuestro enfoque tecnológico y sobre todo humano.
                  </p>  
                </div>
              </Col>

              <Col sm={5}>
                <span className='title-footer expand'>
                  <div className='min-weight'>Información</div> útil
                </span>

                <div className='div-links-footer'>
                  <Row className='pb-5'>
                    <Col md="auto"> 
                      <a
                        href='https://www.decreditos.com/preguntas-frecuentes?hsLang=en'
                        className='link-info-footer'
                      >
                        Preguntas Frecuentes
                      </a>
                    </Col>
                    <Col xs lg="1"></Col>
                    <Col md="auto"> 
                      <a
                        href='https://www.decreditos.com/atenci%C3%B3n-al-usuario-financiero?hsLang=en'
                        className='link-info-footer'
                      >
                        Información al Usuario Financiero
                      </a>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="auto"> 
                      <a
                        href='https://www.decreditos.com/politicas-de-privacidad?hsLang=en'
                        className='link-info-footer'
                      >
                        Políticas de Privacidad
                      </a>
                    </Col>
                    <Col xs lg="1"></Col>
                    <Col md="auto">
                      <a
                        href='https://www.decreditos.com/terminos-y-condiciones?hsLang=en'
                        className='link-info-footer'
                      >
                        Términos y Condiciones
                      </a>
                    </Col>
                  </Row>
                </div>
                
              </Col>

              <Col sm>
                <span className='title-footer'>
                <div className='min-weight'>Nuestras</div> redes
                </span>
                <div className='div-social-footer'>
                  <a
                    href='https://www.instagram.com/decreditos/'
                    className='link-social-footer'
                  >
                    <img 
                      className='icon-social-footer'
                      src={IconInstagram}
                      alt="Instagram"
                    />
                  </a>
                  <a
                    href='https://www.facebook.com/decreditos/'
                    className='link-social-footer'
                  >
                    <img 
                      className='icon-social-footer'
                      src={IconFacebook}
                      alt="Facebook"
                    />
                  </a>
                  <a
                    href='https://www.youtube.com/channel/UCTzKZL1NkbqP42mtNAFJqOQ'
                    className='link-social-footer'
                  >
                    <img 
                      className='icon-social-footer'
                      src={IconYouTube}
                      alt="Youtube"
                    />
                  </a>
                  <a
                    href='https://www.linkedin.com/company/decreditos-sa/'
                    className='link-social-footer'
                  >
                    <img 
                      className='icon-social-footer'
                      src={IconLinkedin}
                      alt="LinkedIn"
                    />
                  </a>
                </div>
              </Col>
            
            </Row>

            <Row>

              <Col className='row-separator-footer'>
                <div className='separator-footer'></div>
              </Col>

            </Row>

            <Row className='pb-3'>
              <Col sm>
                <div className='div-logo-footer'>
                  <img 
                    src={Logo}
                  />
                </div>
              </Col>

              <Col sm>
                <p className='copyright-footer'>
                  © decreditos.com 2022. Todos los derechos reservados.
                </p>  
              </Col>

              <Col sm className='col d-flex justify-content-end'>
                <div className='div-btn-footer'>
                  <button 
                    className='btn-footer'
                    onClick={onClickUp}
                  >
                    Subir
                  </button>
                </div>
              </Col>
            </Row>

          </div>
          
        </Card.Footer>
      }
    </>
  );
};