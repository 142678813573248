
import React from 'react';
import HomeButton from '../Buttons/HomeButton/HomeButton';
import money from '../../assets/images/money.svg'
import calculator from '../../assets/images/calculator.svg'
import './BoxButtonsHome.scss';

const BoxButtonsHome = ({menu}) => {
  const clearMenu = menu.filter(item => item.name !== "")
  const prendarios = clearMenu.find(item => item.name === "Prendarios")

  const separateTitle = (title, index) => {
    return title.split(" ")[index];
  }

  const buildPpalButtons = () => {
    const ppal = prendarios.items.filter(item => item.active && item.name !== "Mis Operaciones")

    return ppal.map((button, index) => (
      <div key={index}>
        <a href={button.url}>
          <div className={button.name === "Calcular cuotas" ? "button button-right" : "button button-left"}>
            <div className='button__text'>{button.name}</div>
            <div>
              <img
                src={button.name === "Calcular cuotas" ? calculator : money}
                alt={button.name}
                className={button.name === "Calcular cuotas" ? 'button__img button__img-right' : 'button__img button__img-left'} />
            </div>
          </div>
        </a>
      </div>
    ))
  }

  const button1 = () => {
    let data = [];
    const onlyMenu = ["Tienda Virtual", "Prendarios", "Mi Billetera"]
    const menu = clearMenu.filter(item => item.active && onlyMenu.includes(item.name))
    menu.map((item, index) => {
      let url = item.url
      let name = item.name
      let color = item.color
      if (name === "Prendarios") {
        const itemPrendario = prendarios.items.find(item => item.active && item.name === "Mis Operaciones")
        url = itemPrendario.url
        name = itemPrendario.name
        color = itemPrendario.color
      }
      data.push({url, name, color})
    })
    return data;
  }

  const button2 = () => {
    let data = [];
    const onlyMenu = ["Mis Seguros", "Mis Vehículos"]
    const menu = clearMenu.filter(item => item.active && onlyMenu.includes(item.name))
    let className = "column-button";
    menu.map((item, index) => {
      let url = item.url
      let name = item.name
      let color = item.color
      if (name === "Mis Seguros") {
        const itemSecure = item.items.find(item => item.active && item.name === "Cotizar / Emitir");
        url = itemSecure.url
      }
      if (name === "Mis Vehículos") {
        className = "column-button-2"
      }
      data.push({url, name, color, className})
    })
    return data;
  }
  const buildButtons = () => {
    let className = "column-button-all"

    return button1().map((item, index) => {
        let name = item.name
        return (
          <>
            <Button item={item} className={className} key={index} />
            {(name === "Mis Operaciones") &&
              button2().map((item, index)  => <Button item={item} className={item.className} key={index} />)}
          </>
        )
      }
    )
  }

  const Button = ({item, className}) => (
    <div className={className} >
      <HomeButton
        link={item.url}
        textLight={separateTitle(item.name, 0)}
        textBold={separateTitle(item.name, 1)}
        lineColor={item.color}
      />
    </div>
  )

  return(
    <div className="content-box">
      <div className='row-button'>
        {buildPpalButtons()}
      </div>
      <div className='row-button'>
        {buildButtons()}
      </div>
    </div>
  )
}

export default BoxButtonsHome;