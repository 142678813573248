import React from "react";
import "./HomeButton.css";

const HomeButton = ({
  link,
  textLight = null,
  textBold = null,
  lineColor = null,
}) => {
  return (
    <div className="home-button">
      <a className="home-link" href={link}>
        {textLight} <strong>{textBold}</strong>
      </a>
      <div className="home-div-line">
        <hr style={{ backgroundColor: lineColor }} />
      </div>
    </div>
  );
};

export default HomeButton;
