import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '../Backdrop/Backdrop';
import {Navbar} from '../OnboardingMenu/OnboardingMenu';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg'

import './MenuLateral.scss'
import {ButtonHeader} from '../ButtonHeader/ButtonHeader';


/**
 *
 * @param isOpen
 * @param onOpenMenu
 * @param onCloseMenu
 * @param logout
 * @param menu
 * @param loading
 * @param token
 * @param platformKey
 * @param onInternalRedirect
 * @returns {JSX.Element}
 * @constructor
 */
const MenuLateral = ({
    isOpen,
    onOpenMenu,
    onCloseMenu,
    menu,
    loading,
    token,
    platformKey = null,
    onInternalRedirect,
}) => {

    return (
        <>
            <aside className={`OnboardingMenu ${isOpen ? 'OnboardingMenu--open' : ''}`}>
                <div className="OnboardingMenu__top">
                    <div className="OnboardingMenu__top-container">
                        <span>Menú <ButtonHeader onClick={onCloseMenu} Icon={CloseIcon} variant="secondary" /></span>

                    </div>
                </div>
                <nav className="OnboardingMenu__navbar-container">

                    <Navbar
                        menu={menu}
                        token={token}
                        platformKey={platformKey}
                        onInternalRedirect={onInternalRedirect}
                        onCloseMenu={onCloseMenu}
                    />

                </nav>
            </aside>
            {isOpen && (
                <Backdrop
                    onClick={isOpen ? onCloseMenu : onOpenMenu}
                    onBack={isOpen ? onCloseMenu : onOpenMenu}
                    index={150}
                    className="OnboardingMenu__backdrop"
                />
            )}

        </>
    )
}

MenuLateral.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    menu: PropTypes.object.isRequired,
    onOpenMenu: PropTypes.func.isRequired,
    onCloseMenu: PropTypes.func.isRequired,
    onInternalRedirect: PropTypes.func.isRequired,
    token: PropTypes.string,
    platformKey: PropTypes.string,
  };
export default MenuLateral;