import React, {useEffect} from 'react';
import DefaultLayout from './../layouts/DefaultLayout/DefaultLayout';
import {Col, Container, Row} from "react-bootstrap";
import newsAPI from "../../api/newsAPI";
import useAsyncData from "../../hooks/useAsyncData";
import AsyncContent from "../../components/AsyncContent/AsyncContent";

import './NewsPage.scss';

const NewsPage = () => {
  const [loadNews, news, newsLoading, newsError] = useAsyncData(async ({setLoading, setData, setError}, values) => {
    return newsAPI.index({})
      .then(response => setData(response.data.data))
      .catch(err => setError(err))
  });

  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('id', 'hs-script-loader');
    script.type = 'text/javascript';
    script.src = 'https://js.hs-scripts.com/7918903.js';
    script.async = true;
    document.body.appendChild(script);
    loadNews();
  }, [loadNews]);

  return (
    <DefaultLayout title="Novedades">
      <div className="NewsPage">
        <div className="main-container">
          <Container fluid="xl" className="container--xs-full">
            <div className="page-card">
              <h2 className="page-card__title">
                Novedades
              </h2>
              <div className="page-card__content">
                <div className="">
                  <Row>
                    <AsyncContent hasError={newsError} isLoading={newsLoading} onRetryHandler={loadNews}>
                      {news && news.length > 0
                        ? news.map(newsItem => (
                          <Col key={newsItem.id} xs={12} sm={6} lg={4}>
                            <a href={newsItem.link}>
                              {newsItem.title}
                            </a>
                          </Col>
                        )) : (
                          <Col xs={12}><h5 className="no-records"></h5></Col>
                        )
                      }
                    </AsyncContent>
                  </Row>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </DefaultLayout>
  );
}

NewsPage.propTypes = {

};

export default NewsPage;