import React from "react";

export function Banner({title, path, link}) {
  return (
    <a target="_blank" href={link}>
      <div className='banner'>
          <img src={path}  alt={title} loading='lazy'/>
      </div>
    </a>
  )
}